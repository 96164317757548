<template>
<loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="">
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-12">
                <div class="card-header">
                  <h3 class="card-title"> Dashboard </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <section class="content" v-if="this.action.includes('27')">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6">
            <router-link to="/clientlist" @click.prevent="toggleMenu" class="small-box-footer">
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>{{this.total_customer}}</h3>
                  <p>Total Customer</p>
                </div>
                <div class="icon"> <i class="ion ion-person-add"></i> </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-6">
            <router-link to="/completeorder" @click.prevent="toggleMenu" class="small-box-footer">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{this.total_invoice}}</h3>
                  <p>Total Order</p>
                </div>
                <div class="icon"> <i class="ion ion-stats-bars"></i> </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-6">
            <router-link to="/completeorder" @click.prevent="toggleMenu" class="small-box-footer">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{{this.completed_invoice}}</h3>
                  <p>Total Completed</p>
                </div>
                <div class="icon"> <i class="ion ion-bag"></i> </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-6">
            <router-link to="/pendingorder" @click.prevent="toggleMenu" class="small-box-footer">
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3>{{this.pending_invoice}}</h3>
                  <p>Total Pending</p>
                </div>
                <div class="icon"> <i class="ion ion-pie-graph"></i> </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"> <i class="fas fa-chart-pie mr-1"></i> Customer</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <vue3-chart-js v-if='chartdrow' :id="id" ref="chartRef" :type="type" :data="datasetJson"></vue3-chart-js>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"> <i class="fas fa-chart-pie mr-1"></i> Invoice</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <vue3-chart-js v-if='chartdrow' :id="id" ref="chartRef" :type="type" :data="datasetJson2"></vue3-chart-js>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 connectedSortable">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"> <i class="fas fa-chart-pie mr-1"></i> Completed Invoice </h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <vue3-chart-js v-if='chartdrow' :id="id" ref="chartRef" :type="type" :data="datasetJson3"></vue3-chart-js>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 connectedSortable">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"> <i class="fas fa-chart-pie mr-1"></i> Day Wise Payment </h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <vue3-chart-js v-if='chartdrow' :id="id" ref="chartRef" :type="type" :data="datasetJson4"></vue3-chart-js>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { ref } from 'vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";


export default { 
  name: 'BasicChartSample',  
  components:{    
        Vue3ChartJs,         
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar,                    
      },
  computed: {
    currentUser() {
      console.log(this.$store.state.auth.user);
      return this.$store.state.auth.user;
    }
  },    
  data() {          
      return { 
        resourcesaction:[], 
        company_id:this.$store.state.auth.user.data[0].company_id,
        company_name:this.$store.state.auth.user.data[0].company_name,
        user_id:this.$store.state.auth.user.data[0].id, 
        role_id:this.$store.state.auth.user.data[0].role_id,
        action:[],
        myData: null,      
        total_customer: '',
        total_invoice: '',  
        completed_invoice: '',
        pending_invoice: '',
        id:'bar',
        type:'bar',
        datasetJson:{
            labels: [],
            datasets: [{
              label: 'Customer',
              backgroundColor: ['#2C91FE'],
              data:[]
              }] 
          },
        datasetJson2:{
            labels: [],
            datasets: [{
              label: 'Invoice',
              backgroundColor: ['#FD9A9A'],
              data:[]
              }] 
          },
          datasetJson3:{
            labels: [],
            datasets: [{
              label: 'Completed Invoice',
              backgroundColor: ['#F2B285'],
              data:[]
              }] 
          },
          datasetJson4:{
            labels: [],
            datasets: [{
              label: 'Daywise Payment',
              backgroundColor: ['#16CD99'],
              data:[]
              }] 
          },
        chartdrow:false,        
    }
  },  
  ///props:['id'],
  methods:{
    async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resources = response.data;               
              this.action1 = response.data.data[0].action_ids;               
              this.action = this.action1.split(","); 
              //console.log(this.action.includes('12'));           
              console.log(this.action)
              //console.log(config.hostname)
              
        },  
      setupDrowGraph () {     
        var  chartRef = ref(null)
        var  doughnutChart =  {data:this.datasetJson} 
        var  doughnutChart2 =  {data:this.datasetJson2} 
        var  doughnutChart3 =  {data:this.datasetJson3} 
        var  doughnutChart4 =  {data:this.datasetJson4} 
        return {
            doughnutChart,
            doughnutChart2,
            doughnutChart3, 
            doughnutChart4,     
            chartRef      
          }
      }
  }, 
 mounted() {  
   if (!this.currentUser) {
      console.log(this.currentUser)
      this.$router.push('/login');
    }  
   setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000); 
   var thisObj = this;
   this.getAction();
    

	axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/dashboasrd",  {
    user_id: this.user_id, 
    company_id: this.company_id   
	})
      .then(res => {
        //alert(res.data.data)
        thisObj.resources = res.data.data;       
        console.log(thisObj.resources); 
        this.total_customer = this.resources[0].total_customer[0].total_customer;
        this.total_invoice = this.resources[0].invoice[0].total_invoice;
        this.completed_invoice = this.resources[0].completed_invoice[0].completed_invoice;
        this.pending_invoice = this.resources[0].pending_invoice[0].pending_invoice;
        this.categories = this.resources[0].daywise_client_total; 
       
        var labelData = this.resources[0].daywise_client_total.map((m) => m.date);
        var labelDataVal = this.resources[0].daywise_client_total.map((m) => m.dayClientTotal);

        var invoiceData = this.resources[0].daywise_invoice.map((m) => m.date);
        var invoiceDataVal = this.resources[0].daywise_invoice.map((m) => m.total_invoice);

        var completedinvoiceData = this.resources[0].daywise_completed_invoice.map((m) => m.date);
        var completedinvoiceDataVal = this.resources[0].daywise_completed_invoice.map((m) => m.total_complated_invoice);

        var paymentData = this.resources[0].daywise_payment.map((m) => m.date);
        var paymentDataVal = this.resources[0].daywise_payment.map((m) => m.total_amount_invoice);
  
        thisObj.chartdrow = true;
        thisObj.datasetJson.labels = labelData;
        thisObj.datasetJson.datasets[0].data = labelDataVal;       

        thisObj.datasetJson2.labels = invoiceData;
        thisObj.datasetJson2.datasets[0].data = invoiceDataVal;       

        thisObj.datasetJson3.labels = completedinvoiceData;
        thisObj.datasetJson3.datasets[0].data = completedinvoiceDataVal;       

        thisObj.datasetJson4.labels = paymentData;
        thisObj.datasetJson4.datasets[0].data = paymentDataVal;       
        
        thisObj.setupDrowGraph();

      })
      .catch(error => {        
        console.log(error)         
      })
  }, 
 
};
</script>

<style> 
  .card { margin-top: 0px !important;}
  @import '../../assets/dist/css/custom.css'  
</style>